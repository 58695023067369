
<template>
  <div name="notice">
				<div class="button-text right save-notice" v-show="hasSaved"> <i class="icon fas fa-check"/> {{title}} Saved</div>
                <div class="button-text right save-notice fail" v-show="hasFailed"> <i class="icon fas fa-times"/> There was an issue on save</div>
  </div>
</template>

<script>
export default {
  name: "notice",
  props: ["title"],
  data() {
    return {
      hasSaved: false,
      hasFailed: false
    };
  },
  methods: {
    saved() {
      this.hasFailed = false;
      this.hasSaved = true;
    },
    failed() {
      this.hasFailed = true;
      this.hasSaved = false;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
